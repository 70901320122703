import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import media from '../../components/css/media'

import { parseQueryString } from '../../utils/generic'
import { doActivate } from '../../adapters/auth'
import useDeviceDetect from '../../hooks/useDeviceDetect'

import ExclamationCircleIcon from '../../images/icon_exclamation_circle.svg'
import CheckCircleIcon from '../../images/icon_check_circle.svg'

const Content = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;

  svg {
    margin: 0 auto;
    width: 3em !important;
    height: 3em;
  }

  ${media.desktop`
    justify-content: center;
  `};
`

const ExclamationCircleImg = styled.img`
  width: 50px;
  margin: 0 auto;
`

const CheckCircleImg = styled.img`
  width: 50px;
  margin: 0 auto;
`

const ActivateAccount = ({ pageContext: { title } }) => {
  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      otherBannersApi {
        values {
          lang
          otherBanners {
            background
            backgroundMobile
            banner_title_text
            text_color
            type
          }
        }
      }
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    otherBannersApi: { values },
    customSlugsApi: { values: customSlugValues },
  } = data

  const bannerExistForLocale = values.some((row) => row.lang === intl.locale)
  const bannerExistForPage =
    bannerExistForLocale &&
    values
      .filter((row) => row.lang === intl.locale)[0]
      .otherBanners.some((i) => i.type === 'registration')
  const banner = bannerExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .otherBanners.filter((i) => i.type === 'registration')[0]
    : null

  const slugsExistForLocale = customSlugValues.some(
    (row) => row.lang === intl.locale
  )
  const slugsExistForPage =
    slugsExistForLocale &&
    customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = slugsExistForPage
    ? customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const loginSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'login'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'login')[0]
        .language_route
    : 'login'

  const image = banner ? banner.background : null
  const imageMobile = banner ? banner.backgroundMobile : null
  const text = banner ? banner.banner_title_text : null
  const textColor = banner ? banner.text_color : null

  const [response, setResponse] = useState(null)

  const location = useLocation()
  const params = parseQueryString(location.search)

  useEffect(() => {
    async function activateAccount() {
      const response = await doActivate(params.token)
      setResponse(response)

      if (response.ok)
        setTimeout(() => navigate(`/${intl.locale}/${loginSlug}/`), 5000)
    }

    activateAccount()
  }, [params.token, intl.locale, loginSlug])

  return (
    <SplitLayout
      img={isMobile ? imageMobile : image}
      text={text}
      textColor={textColor}
      hideLeftSideOnMobile={true}
    >
      <Seo title={title} />

      <Content>
        {response && !response.ok && (
          <>
            <ExclamationCircleImg
              src={ExclamationCircleIcon}
              alt='Activation failed.'
            />
            <h1>
              <FormattedMessage id='accountActivation.accountActivatedError' />
            </h1>
            <p>{response.error.message}</p>
          </>
        )}

        {response && response.ok && (
          <>
            <CheckCircleImg src={CheckCircleIcon} alt='Activation success.' />
            <h1>
              <FormattedMessage id='accountActivation.accountActivatedSuccess' />
            </h1>
          </>
        )}
      </Content>
    </SplitLayout>
  )
}

export default ActivateAccount
